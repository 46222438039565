<script>
import { Container, Header, Main, Row, Col, Table, TableColumn, Button, Checkbox, Select, Option, Divider, RadioGroup, RadioButton, DatePicker, Message,Tooltip,CheckboxGroup,Input } from 'element-ui'
import { Mixin } from '@/mixins/util.js'
import Excel from 'exceljs'
import { saveAs } from 'file-saver'
export default {
  name: 'inventoryDetail',
  mixins: [Mixin],
  components: {
    'el-table': Table,
    'el-table-column': TableColumn,
    'el-button': Button,
    'el-container': Container,
    'el-header': Header,
    'el-main': Main,
    'el-row': Row,
    'el-col': Col,
    'el-checkbox': Checkbox,
    'el-checkbox-group': CheckboxGroup,
    'el-select': Select,
    'el-option': Option,
    'el-divider': Divider,
    'el-radio-group': RadioGroup,
    'el-radio-button': RadioButton,
    'el-date-picker': DatePicker,
    'el-tooltip': Tooltip,
    'el-input': Input,
  },
  data(){
    return {
      selected_date:null,
      inventoryData:null,
      product_type:'2',
      sales_type1:true,
      sales_type2:true,
      sales_type3:true,
      pickerOptions:{
        disabledDate(time) {
          if (time.getFullYear() < 2020 || time.getFullYear() > new Date().getFullYear()) return true
          if (time.getFullYear() == new Date().getFullYear())
            return time.getMonth() >= new Date().getMonth() + 1
          return false
        }
      },
      height:'75vh',
      month_range:[],
      record_form_table:{
        '005_000001_001_00000000':'EC',
        '005_000002_001_00000000':'EC',
        '005_000005_001_00000000':'EC',
        '005_000033_001_00000000':'MOMO',
        '005_000035_001_00000000':'YHOO',
        '005_000036_001_00000000':'SHOPEE',
        '005_000038_001_00000000':'SHOPLINE',
        '005_000039_001_00000000':'SHOPIFY',
        '005_000041_001_00000000':'PCHOME',
        '005_000043_001_00000000':'ETMALL',
        '005_000045_001_00000000':'BOOKS',
        '005_000048_001_00000000':'FRIDAY',
      },
      filter_options:[],
      showFilterArea:true,
      pn_selected:'',
      pc_selected:'',
      p_type:['2','3','4','5'],
      stateList:[],
    }
  },
  watch:{
  },
  computed:{
    excludeList(){
      return this.stateList
        .filter(d=>d.product_state === false)
        .map(d=>d.product_code)
    },
    selected_source(){
      return this.filter_options.filter(d=>d.checked===true).map(d=>d.value)
    },
    raw_data(){
      let data = []
      if(this.inventoryData===null) return []
      for (const [product_code, content] of Object.entries(this.inventoryData)) {
        for (const quality of [1,2]) {
          for (const [month,stock_info] of Object.entries(content[quality])) {
            for (const info of stock_info) {
              const isExist = data.find(d=>d.product_code === product_code && d.quality === quality && d.record_from === info[0])
              if(isExist === undefined){
                data.push({
                  product_code,
                  product_name:content.product_name,
                  product_type:content.product_type,
                  quality,
                  quality_name:quality===1?'良':'不良',
                  record_from:info[0]
                })
              }
              const index = data.findIndex(d=>d.product_code === product_code && d.quality === quality && d.record_from === info[0])
              
              data[index][`${month}_start`] = info[1]      //start of month
              data[index][`${month}_end`] = info[2]        //end of month
              data[index][`${month}_in`] = info[3]         //in
              data[index][`${month}_return`] = info[4]     //return
              data[index][`${month}_out`] = info[5]        //out
            }
          }
        }
      }
      if(sessionStorage.getItem('session_id')==='3294228999')
      return data.map((d,i)=>{
        d.product_code = `OOO_${i}`
        d.product_name = `XXX_${i}`
        return d
      }).filter(d=>this.excludeList.includes(d.product_code) === false)
      else
        return data.filter(d=>this.excludeList.includes(d.product_code) === false)
    },
    data_with_filter_source_pnpc(){
      return this.raw_data
        .filter(d=>{
          return this.selected_source.includes(d.record_from)
        })
        .filter(d=>{
          if(this.pc_selected.length === 0) return d
          return this.pc_selected.includes(d.product_code)
        })
        .filter(d=>{
          if(this.pn_selected.length === 0) return d
          return this.pn_selected.includes(d.product_name)
        })
    },
    data_with_filter_type(){
      if(this.product_type === '1') return this.data_with_filter_source_pnpc
      if(this.p_type.length===0) return []
      return this.data_with_filter_source_pnpc.filter(d=>{
        return this.p_type.includes(d.product_type)
      })
    },
    union_data(){
      return this.data_with_filter_type.reduce((a,c)=>{
        const exist = a.find((d=>d.product_code === c.product_code && d.quality === c.quality))
        if(exist===undefined) a.push({
          product_code:c.product_code,
          product_name:c.product_name,
          product_type:c.product_type,
          quality:c.quality,
          quality_name:c.quality_name,
        })

        const index = a.findIndex((d=>d.product_code === c.product_code && d.quality === c.quality))
        for (const month of this.month_range) {
          if(a[index][`${month}_start`] === undefined){
            a[index][`${month}_start`] = 0
            a[index][`${month}_end`] = 0
            a[index][`${month}_in`] = 0
            a[index][`${month}_return`] = 0
            a[index][`${month}_out`] = 0
          }
          if(c[`${month}_start`] === undefined){
            c[`${month}_start`] = 0
            c[`${month}_end`] = 0
            c[`${month}_in`] = 0
            c[`${month}_return`] = 0
            c[`${month}_out`] = 0
          }
          a[index][`${month}_start`] += c[`${month}_start`]   
          a[index][`${month}_end`] += c[`${month}_end`]     
          a[index][`${month}_in`] += c[`${month}_in`]        
          a[index][`${month}_return`] += c[`${month}_return`]    
          a[index][`${month}_out`] += c[`${month}_out`]     
        }
        
        return a
      },[])
    },
    pn_options(){
      return this.union_data
        .filter(d=>d.product_name !== '')
        .reduce((acc,cur)=>{
          if(!acc.find(option=>option === cur.product_name)){
            acc.push(cur.product_name)
          }
          return acc
        },[])
    },
    pc_options(){
      return this.union_data
        .filter(d=>d.product_code !== '')
        .reduce((acc,cur)=>{
          if(!acc.find(option=>option === cur.product_code)){
            acc.push(cur.product_code)
          }
          return acc
        },[])
    }
  },
  methods:{
    checkDate(date){
      const [start,end] = date
      const s = start.split('-')
      const e = end.split('-')
      const between = Math.abs( (s[0]*12 + parseInt(s[1])) - (e[0]*12 + parseInt(e[1])) )
      if(between+1 > 3){ 
        Message.error('Maximum is 3 month')
        this.selected_date = null
      }
    },
    async getInventoryData(){
      if(this.selected_date === null) {
        Message.error('Please select date')
        return
      }
      if(this.selected_date !== null){
        this.month_range = []
        const [start,end] = this.selected_date
        const s = start.split('-')
        const e = end.split('-')
        const between = Math.abs( (s[0]*12 + parseInt(s[1])) - (e[0]*12 + parseInt(e[1])) )
        if(between+1<=3){
          this.month_range = [start]
          for(let i = 0;i < between-1;i++){
            const t = new Date(s[0],s[1]*1+i)
            this.month_range.push(`${t.getFullYear()}-${t.getMonth()+1>10?t.getMonth()+1:`0${t.getMonth()+1}`}`)
          }
          if(between>0)
            this.month_range.push(end)
        }
      } 
      const s_id = sessionStorage.getItem('session_id')
      const url = `https://www.bamb.asia/suppliers/inventoryInfoByMonthRange`
      const config = {
        method: "POST",
        headers: {
          "Content-Type": "text/plain;charset=UTF-8",
        },
        body: `sessionid=${s_id}&product_type=${this.product_type}&begin_month=${this.selected_date[0]}-01&end_month=${this.selected_date[1]}-01`,
      };

      await fetch(url, config)
        .then((res) => res.text())
        .then((rep) => {
          rep = JSON.parse(this.RemoveNonValidJSONChars(rep))
          if (rep.errno === "1") {
            this.inventoryData = rep.records.records
            this.filter_options = this.raw_data.reduce((acc,cur)=>{
              let label = this.record_form_table[cur.record_from]
              if(label === undefined){
                const retail = cur.record_from.split('_')
                label = retail[retail.length-1]
              }
              if(!acc.find(option=>option.label === label)){
                acc.push({label,checked:true,value:cur.record_from})
              }
              return acc
            },[])
            this.$refs.table.doLayout()
          }
        })
    },
    priceformatter(row, column){
      if(!isNaN(row[column.property])) return row[column.property].toLocaleString() 
      return row[column.property]
    },
    typeColor(row){
      let color = 'black' 

      switch(row.product_type){
        case '2':
          color = '#5adb15' 
          break
        case '3':
          color = '#0aa7a9' 
          break
        case '4':
          color = '#cb6c0e' 
          break
        case '5':
          color = '#d6425b' 
          break
      }
      return color
    },
    exportExcelHandler(){
      if(this.union_data.length===0) return 
      const workbook = new Excel.Workbook()
      const worksheet = workbook.addWorksheet()
      worksheet.addRow(['商品名','品番','狀態'])
      worksheet.mergeCells(1, 1, 2, 1)
      worksheet.mergeCells(1, 2, 2, 2)
      worksheet.mergeCells(1, 3, 2, 3)
      worksheet.getCell(1, 1).alignment = {
        vertical: "middle",
        horizontal: "center",
      }
      worksheet.getCell(1, 2).alignment = {
        vertical: "middle",
        horizontal: "center",
      }
      worksheet.getCell(1, 3).alignment = {
        vertical: "middle",
        horizontal: "center",
      }
      const header1 = worksheet.getRow(1)
      const header2 = worksheet.getRow(2)
      for (const [index,month] of this.month_range.entries()) {
        header1.getCell(index*4+4).value = month
        header2.getCell(index*4+4).value = '期初在庫'
        header2.getCell(index*4+4+1).value ='入庫'
        header2.getCell(index*4+4+2).value = '返送'
        header2.getCell(index*4+4+3).value = '出荷'
        //merge cell
        if(index === this.month_range.length-1){
          header2.getCell(index*4+4+4).value = '殘在庫數'
          worksheet.mergeCells(1, index*4+4, 1, index*4+4+4)
          worksheet.getCell(1, index*4+4).alignment = {
            vertical: "middle",
            horizontal: "center",
          }
        }else{
          worksheet.mergeCells(1, index*4+4, 1, index*4+4+3)
          worksheet.getCell(1, index*4+4).alignment = {
            vertical: "middle",
            horizontal: "center",
          }
        }
      }
      for (const d of this.union_data) {
        let row = [d.product_name,d.product_code,d.quality_name] 
        for (const [index,month] of this.month_range.entries()) {
          row.push(d[`${month}_start`])
          row.push(d[`${month}_in`])
          row.push(d[`${month}_return`])
          row.push(d[`${month}_out`])
          if(index === this.month_range.length-1) row.push(d[`${month}_end`])
        }
        worksheet.addRow(row)
      }

      workbook.xlsx.writeBuffer().then(data => {
        let blob = new Blob([data], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        saveAs(blob, `在庫詳細.xlsx`)
      })
    },
    getSetState(args) {
      if(this.stateList.length ===0){
        this.stateList = args //init for first time
        // Message.success('Get state list success')
      }
      else{
        //modify single set
        const index = this.stateList.findIndex(item=>item.product_code === args.product_code)
        this.$set(this.stateList,index,args)
        // Message.success('update state success')
      }
    },
  },
  mounted(){
    // this.getSalesData()
    this.$eventBus.$on('getSetState', this.getSetState, this)
  }
}
</script>

<template src="./template.html"></template>
<style lang="scss" src="./style.scss" scoped></style>
